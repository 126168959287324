import React from "react"
import { Router } from "@reach/router";
import Booking from '../components/Booking'
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import Breadcrumb from "../components/Breadcrumb"
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"

export default function BookingPage() {
  const { language } = React.useContext(I18nextContext);
  const { title } = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
		<title>{title}</title>
        <link rel="icon" href={favicon} />
        <meta name="title" content={title} />
        <meta name="og:title" content={title} />
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
        <Router basepath={`/${language}/booking/`}>
          <Breadcrumb path="/" step={'precio-y-disponibilidad'}/>
          <Breadcrumb path="/experience/:experienceId/product/:productId/" step={'precio-y-disponibilidad'}/>
          <Breadcrumb path="/experience/:experienceId/" step={'precio-y-disponibilidad'}/>
        </Router> 
	      <Router basepath={`/${language}/booking/`}>
          <Booking path="/experience/:experienceId/product/:productId/"/>
          <Booking path="/experience/:experienceId/"/>
        </Router>
    </Layout>
  )
}
